<template>
  <div>
    <!--教室课表试图 -->
    <div class="work-title-all flex justify-content-between aligin-items-center  margin-tb-sm">
      <div class="work-title-content flex aligin-items-center flex-wrap-wrap" style="width:60%;">
        <el-popover width="150" trigger="click" >
          <el-input v-model="classteacher.schoolInput" placeholder="" size="mini"></el-input>
          <el-checkbox-group v-model="classteacher.schoolList">
            <el-checkbox class="padding-xs" :label="item.label" v-for="(item, index) of shoolList" :key="index" />
          </el-checkbox-group>
          <div slot="reference">
            <span>校区</span>
            <i class="el-icon-caret-bottom" />
            <i class="border-right" />
          </div>
        </el-popover>
        <el-popover width="150" trigger="click">
          <el-input v-model="classteacher.classInput" placeholder="" size="mini" />
          <el-checkbox-group v-model="classteacher.classList">
            <el-checkbox class="padding-xs" label="空" />
            <el-checkbox class="padding-xs" label="张老师" />
          </el-checkbox-group>
          <div slot="reference">
            <span>班级</span>
            <i class="el-icon-caret-bottom" />
            <span class="border-right" />
          </div>
        </el-popover>
        <el-popover width="150" trigger="click">
          <el-input v-model="classteacher.teacherInput" placeholder="" size="mini" />
          <el-checkbox-group v-model="classteacher.teacherList">
            <el-checkbox class="padding-xs" label="空" />
            <el-checkbox class="padding-xs" label="张老师" />
          </el-checkbox-group>
          <div slot="reference">
            <span>教师</span>
            <i class="el-icon-caret-bottom" />
            <span class="border-right" />
          </div>
        </el-popover>
        <el-popover width="150" trigger="click">
          <el-input v-model="classteacher.teacherInput" placeholder="" size="mini" />
          <el-checkbox-group v-model="classteacher.teacherList">
            <el-checkbox class="padding-xs" label="空" />
            <el-checkbox class="padding-xs" label="张老师" />
          </el-checkbox-group>
          <div slot="reference">
            <span>教师</span>
            <i class="el-icon-caret-bottom" />
            <span class="border-right" />
          </div>
        </el-popover>
    
        <el-popover width="150" trigger="click">
          <el-radio-group v-model="classteacher.rememberClass">
            <el-radio class="padding-xs" label="记上课" />
            <el-radio class="padding-xs" label="未上课" />
          </el-radio-group>
          <div slot="reference">
            <span>是否记上课</span>
            <i class="el-icon-caret-bottom" />
            <span class="border-right" />
          </div>
        </el-popover>
      </div>
    </div>
    
    <div class="work-title-status flex margin-top-sm aligin-items-center justify-content-between">
      <div class="flex aligin-items-center">
        <div class="text-gray flex aligin-items-center ">
          <div>校区：</div>
          <div v-for="(btn, btnIndex) in orderSatus" :key="btnIndex">
            <div :class="{ 'margin-left': btnIndex > 0 }">
              <el-button size="mini">
                {{ btn }}
                <i class="el-icon-close" @click="delOderStatus(btnIndex)" />
              </el-button>
            </div>
          </div>
        </div>
      </div>
    
      <div class="text-blue">清空筛选</div>
    </div>
    
    <changeDate @dateChange="dateChange" :types="3" class="margin-tb-xl"></changeDate>
    
    <div style="margin-top: 10px">
      <as-list :initdataList="datalist" v-if="tabPosition === 'week'">
        <el-table-column align="center" prop="period" label="教室"></el-table-column>
        <template v-for="(item, index) in currentDateFilter">
          <el-table-column align="center" prop="StudentName" :label="`周${index + 1} ${item}`"></el-table-column>
        </template>
      </as-list>
    </div>
  </div>
</template>

<script>
  import changeDate from '../../components/changeDate.vue';
  import { formatTime } from '@/utils/util.js';
  export default {
    components: {
      changeDate
    },
    data() {
      return {
        isShow: false,
        classteacher: {
          schoolInput: '', //校区输入
          schoolList: [], //校区
          teacherInput: '', //教师输入
          teacherList: [], //教师
          classInput: '', //班级输入
          classList: [], //班级
          rememberClass: '', //是否记上课
          stuInput: '' //整体搜索
        },
        dates: [],
        tabPosition: 'week',
        orderSatus: ['待支付', '已完成'], // 订单状态
        datalist: [
          {
            period: '早上7:00',
            Monday: 'zhouyi',
            Tuesday: '',
            Wednesday: '',
            Thursday: '',
            Friday: '',
            Saturday: '',
            Sunday: ''
          },
          {
            period: '早上8:00',
            Monday: 'zhouyi',
            Tuesday: '',
            Wednesday: '',
            Thursday: '',
            Friday: '',
            Saturday: '',
            Sunday: ''
          },
          {
            period: '上午9:00',
            Monday: 'zhouyi',
            Tuesday: '',
            Wednesday: '',
            Thursday: '',
            Friday: '',
            Saturday: '',
            Sunday: ''
          },
          {
            period: '上午10:00',
            Monday: 'zhouyi',
            Tuesday: '',
            Wednesday: '',
            Thursday: '',
            Friday: '',
            Saturday: '',
            Sunday: ''
          },
          {
            period: '上午11:00',
            Monday: 'zhouyi',
            Tuesday: '',
            Wednesday: '',
            Thursday: '',
            Friday: '',
            Saturday: '',
            Sunday: ''
          },
          {
            period: '中午12:00',
            Monday: 'zhouyi',
            Tuesday: '',
            Wednesday: '',
            Thursday: '',
            Friday: '',
            Saturday: '',
            Sunday: ''
          },
          {
            period: '下午1:00',
            Monday: 'zhouyi',
            Tuesday: '',
            Wednesday: '',
            Thursday: '',
            Friday: '',
            Saturday: '',
            Sunday: ''
          },
          {
            period: '下午2:00',
            Monday: 'zhouyi',
            Tuesday: '',
            Wednesday: '',
            Thursday: '',
            Friday: '',
            Saturday: '',
            Sunday: ''
          },
          {
            period: '下午3:00',
            Monday: 'zhouyi',
            Tuesday: '',
            Wednesday: '',
            Thursday: '',
            Friday: '',
            Saturday: '',
            Sunday: ''
          },
          {
            period: '下午4:00',
            Monday: '',
            Tuesday: '',
            Wednesday: '',
            Thursday: '',
            Friday: '',
            Saturday: '',
            Sunday: ''
          },
          {
            period: '下午5:00',
            Monday: '',
            Tuesday: '',
            Wednesday: '',
            Thursday: '',
            Friday: '',
            Saturday: '',
            Sunday: ''
          },
          {
            period: '晚上6:00',
            Monday: '',
            Tuesday: '',
            Wednesday: '',
            Thursday: '',
            Friday: '',
            Saturday: '',
            Sunday: ''
          },
          {
            period: '晚上7:00',
            Monday: '',
            Tuesday: '',
            Wednesday: '',
            Thursday: '',
            Friday: '',
            Saturday: '',
            Sunday: ''
          },
          {
            period: '晚上8:00',
            Monday: '',
            Tuesday: '',
            Wednesday: '',
            Thursday: '',
            Friday: '',
            Saturday: '',
            Sunday: ''
          }
        ],
      }
    },
    computed: {
      currentDateFilter() {
        let datesList = [];
        this.dates.map(item => {
          datesList.push(formatTime(item, 'MM月dd日'));
        });
        this.dates = datesList;
        return this.dates;
      }
    },
    methods: {
      setDate(date, dayCount) {
        return new Date(date.getTime() + 1000 * 60 * 60 * 24 * dayCount);
      },
      dateChange(res) {
        this.tabPosition = res.type;
        let startDate = res.date[0];
        this.startDate = startDate;
        let endDate = res.date[1];
        let currentDay = res.date[2];
        this.weekValue = res.date[3];
        if (currentDay.getDay() == 0) {
          this.currentDate = 7;
        } else {
          this.currentDate = currentDay.getDay();
        }
        this.dates = [];
        while (startDate <= endDate) {
          this.dates.push(startDate);
          startDate = this.setDate(startDate, 1);
        }
      },
    },
  }
</script>

<style scoped>
</style>
