<template>
  <div class="padding">
    <!-- 冲突日程 -->
    <div class="work-title bg-white">
      <div class="work-title-all flex justify-content-between aligin-items-center margin-top">
        <div>
          <changeDate @dateChange="dateChange" :types="5"></changeDate>
        </div>

        <div class="work-title-search flex">
          <el-input placeholder="请输入班级/一对一" v-model="conflictSchedule.keyword" class="input-with-select" size="mini"
            style="width: 100%;">
            <el-select v-model="conflictSchedule.typechange" slot="prepend" style="width: 120px;">
              <el-option label="班级/一对一" value="1" />
              <el-option label="教室名称" value="4" />
            </el-select>
            <el-button slot="append" icon="el-icon-search" @click="search" />
          </el-input>
        </div>
      </div>
      <div class="myClient-text flex justify-content-between margin-top">
        <div>共计{{total}}条冲突记录</div>
      </div>
    </div>
    <div class="margin-top">
      <as-list :ajaxMethods="{ list: 'queryConflictList' }" :listQueryProp="queryList" @requestChange="requestSuccess">
        <el-table-column align="center" sortable prop="class_begin_date" label="上课日期" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.class_begin_date | dateTimeFilter('yyyy-MM-dd') }}({{ scope.row.curriculum_week
              }})</span>
          </template>
        </el-table-column>
        <el-table-column prop="arrange_classes_name" label="班级/一对一" width="380"></el-table-column>
        <el-table-column align="" prop="curriculum_name" label="课程"></el-table-column>
        <el-table-column align="center" prop="arrange_teacher_name" label="教师" width="90"></el-table-column>
        <el-table-column align="center" prop="class_room_name" label="教室" width="100"></el-table-column>
        <el-table-column align="center" prop="status" label="操作">
          <template slot-scope="scope">
            <el-dropdown trigger="click">
              <label>
                操作
                <i class="el-icon-arrow-down el-icon-caret-bottom"></i>
              </label>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>编辑</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </as-list>
    </div>
  </div>
</template>

<script>// queryConflictList
  import changeDate from '../../components/changeDate.vue';
  export default {
    components: {
      changeDate,
    },
    data() {
      return {
        total: 0,
        queryList: {
          KeyWord: '',
          class_begin_date: '',
          today: false,
          PageIndex: 1,
          PageSize: 10,
          date_range: ''
        },
        startDate: '',
        tabPosition: '',
        conflictSchedule: {
          classInput: '', //输入班级/一对一
          typechange: ''
        },
        List: [],
      }
    },
    created() {

    },
    methods: {
      search() {
        if (this.conflictSchedule.typechange != 1 && this.conflictSchedule.typechange != 4) return;
        // if (this.conflictSchedule.keyword) 
        {
          this.queryList.room_name = '';
          this.queryList.classes_name = '';
          if (this.conflictSchedule.typechange == 1) this.queryList.classes_name = this.conflictSchedule.keyword;
          if (this.conflictSchedule.typechange == 4) this.queryList.room_name = this.conflictSchedule.keyword;
        }
        this.queryList = JSON.parse(JSON.stringify(this.queryList));
      },
      requestSuccess(data) {

        this.total = data.total || 0;
      },
      dateChange(res) {
        this.tabPosition = res.type;
        let startDate = res.date[0];
        this.startDate = startDate;
        let endDate = res.date[1];
        let currentDay = res.date[2];
        this.weekValue = res.date[3];
        if (currentDay.getDay() == 0) {
          this.currentDate = 7;
        } else {
          this.currentDate = currentDay.getDay();
        }
        this.dates = [];
        while (startDate <= endDate) {
          this.dates.push(startDate);
          startDate = this.setDate(startDate, 1);
        }
        if (res.value) {
          this.queryList.date_range = res.value;
          this.search();
        }
      },
      getdate(d) {
        return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();// + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
      },
      setDate(date, dayCount) {
        return new Date(date.getTime() + 1000 * 60 * 60 * 24 * dayCount);
      },
    },
  }
</script>

<style scoped>
</style>