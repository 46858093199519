import { render, staticRenderFns } from "./classView.vue?vue&type=template&id=04d6a44a&scoped=true&"
import script from "./classView.vue?vue&type=script&lang=js&"
export * from "./classView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04d6a44a",
  null
  
)

export default component.exports