<template>
  <div class="total">
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane disabled label="排课" name="first"></el-tab-pane>

      <el-tab-pane label="课程表" name="two">
        <div class="padding">
          <div class="work-title bg-white flex justify-content-center">
            <el-button round v-for="(item, index) in tabList" :key="index" @click="colorChange(index)" :class="{ 'color-box': tabIndex == index }">
              {{ item.name }}
            </el-button>
          </div>
          
          <div class="margin-tb-lg flex flex-wrap-wrap">
            <el-button size="small" type="primary" @click="goNav('/educationCenter/newSchedule')">新建排课</el-button>
            <el-button size="small" type="primary" >导入排课</el-button> 
           <a href="http://ds.antissoft.com/temp/排课导入模板20210715.xls" style="margin-left:10px" target="_blank">  <el-button size="small" type="info"     >导入模板下载(.xls)</el-button> </a>
      
            <!-- <el-button size="small" class="margin-left" @click="editHandle(2)">一对一排课导入</el-button> -->
          </div>
          <!-- 时间视图-->
          <time-view v-show="tabIndex == 0"></time-view>
          <!-- 教师视图 -->
          <TeacherView v-show="tabIndex == 1"></TeacherView>
          <!-- 教室视图 -->
          <ClassroomView v-show="tabIndex == 2"></ClassroomView>
          <!-- 班级视图 -->
          <ClassView v-show="tabIndex == 3"></ClassView>
        </div>
      </el-tab-pane>
      <el-tab-pane lazy label="日程列表" name="three"><schedule-list /></el-tab-pane>

      <el-tab-pane lazy label="冲突日程" name="fore"><conflict-agenda /></el-tab-pane>
    </el-tabs>

    <OneClassImport :Visible="editVisible && editType == 2" @closepop="closeEditPop"></OneClassImport>
  </div>
</template>
<script>
import OneClassImport from '../components/OneClassImport.vue';
 
import ScheduleList from './child/scheduleList.vue';
import ConflictAgenda from './child/conflictAgenda.vue';

import TimeView from './child/timeView.vue';
import TeacherView from './child/teacherView.vue';
import ClassroomView from './child/classroomView.vue';
import ClassView from './child/classView.vue';
export default {
  components: {
    OneClassImport,
    ScheduleList,
    ConflictAgenda,
    TimeView,
    TeacherView,
    ClassroomView,
    ClassView
  },
  data() {
    return {
      activeName: 'two',
      tabIndex: 0,
      tabList: [
        {
          value: 1,
          name: '时间课程表视图'
        },
        /* {
          value: 2,
          name: '教师课程表视图'
        },
        {
          value: 3,
          name: '教室课程表视图'
        },
        {
          value: 4,
          name: '班级课程表视图'
        } */
      ]
    };
  },
  methods: {
    // 一对一导入
    editHandle(type) {
      this.editVisible = true;
      this.editType = type;
    },
    // 切换视图
    colorChange(index) {
      this.tabIndex = index;
    },
    // 跳转
    goNav(url) {
      this.$router.push(url); 
    }
  }
};
</script>

<style lang="scss" scoped>
.color-box {
  color: #0081ff;
  border: 1px solid #8cc5ff;
  background-color: #e8f5ff;
}

.total {
  width: 100%;
}

.shool {
  margin: 0px 10px;
}

.el-input__icon {
  line-height: 40px;
}

.el-select {
  display: block;
}

.el-calendar__header {
  display: none;
}

.el-calendar-table th {
  border-top: 1px solid #efefef;
  border-left: 1px solid #efefef;

  &:last-child {
    border-right: 1px solid #efefef;
  }
}
</style>
