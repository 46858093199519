<template>
	<el-dialog title="一对一排课导入" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" :show-close='false' width="50%">
		<div >
			<div style="text-align: center;">
				<el-upload class="upload-demo" drag action="#" multiple accept=".xls" @click="openFullScreen1"
					v-loading.fullscreen.lock="fullscreenLoading">
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">上传文件</div>
				</el-upload>
				<div class="margin-top-sm"><i class="iconfont icon-yuandianxiao line-blue"></i>点击<label>下载导入模板</label>’
				</div>
			</div>

			<div class="margin-top-xl border-top-grey padding-top-sm">
				<div style="text-align: center;">导入须知</div>
				<div class="margin-left-xl">
					<div>
						1、填写的学员姓名、课程、校区、上课教室、教师、助教名称、务必与系统中名字一致，且课程为学员在该校区已报的在读课程
					</div>
					<div>
						2、学员在系统中存在重名，且课程、上课校区完全一致时，重复学员不支持导入
					</div>
					<div>
						3、表头中红色字体标示的列为必填项，至少选择一个重复单位及上课时间
					</div>
					<div>
						4、只有开班日期、结班日期、教师、教室都填写，才能正常导入上课时间
					</div>
					<div>
						5、过滤的节假日为机构中心-郊区中心设置-节假日设置下的所有节假日
					</div>
					<div>
						6、一次最多能导入200条数据
					</div>
				</div>
			</div>
			<div class="flex justify-content-flexEnd margin-top-lg">
				<el-button @click="close">关闭</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			Visible: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				dialogVisible: false,
				fullscreenLoading: false,
			}
		},
		watch: {
			Visible(val) {
				this.dialogVisible = val;
			}
		},
		created() {
			this.dialogVisible = this.Visible;
		},
		methods: {
			close() {
				this.$emit('closepop')
			},
			openFullScreen1() {
				this.fullscreenLoading = true;
				setTimeout(() => {
					this.fullscreenLoading = false;
				}, 2000);
			},
		}
	}
</script>

<style>
</style>
