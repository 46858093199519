<template>
  <div class="flex justify-content-between ">
    <div class="flex flex-wrap-wrap" v-show="types != 5"><!-- <el-button>导出课表</el-button> --></div>

    <div>
      <!-- <el-button  size="small" type="primary" v-if="tabPosition === 'week' && types === 1 && !isshow" style="margin-right: 20px;" @click="switchView">
        <i class="iconfont icon-qiehuan text-white"></i>
        {{ weekType }}
      </el-button> -->
      <el-radio-group size="small" v-model="tabPosition" class="margin-right-sm" @change="typesChange" v-show="types === 1 ||types===2">
        <el-radio-button label="day">日</el-radio-button>
        <el-radio-button label="week">周</el-radio-button>
        <el-radio-button label="month">月</el-radio-button>
      </el-radio-group>
      <el-button size="small" icon="el-icon-arrow-left" style="padding: 8px 5px;" @click="changeDate(-1)"></el-button>
      <span class="margin-lr" style="font-size: 16px;font-weight: 600;">{{ currentDateHtml }}</span>
      <el-button size="small" icon="el-icon-arrow-right" style="padding: 8px 5px;" @click="changeDate(1)"></el-button>
      <el-button size="small" style="padding: 8px 8px;" @click="getTodayDay" v-show="types != 5">今天</el-button>
    </div>

    <div class="flex flex-wrap-wrap margin-top-xs" v-show="types != 5">
      <div class="flex flex-wrap-wrap">
        <div class="educationCenter-div bg-grey"></div>
        <span class="educationCenter-lh-25">已记上课</span>
      </div>
      <div class="flex flex-wrap-wrap margin-left-xs">
        <div class="educationCenter-div educationCenter-olorCode-c"></div>
        <span class="educationCenter-lh-25">日程冲突</span>
      </div>
      <!-- <div class="flex flex-wrap-wrap margin-left-xs">
        <div class="educationCenter-div educationCenter-olorCode-b1"></div>
        <span class="educationCenter-lh-25">我的日程</span>
      </div>
      <div class="flex flex-wrap-wrap margin-left-xs">
        <div class="educationCenter-div educationCenter-olorCode-g"></div>
        <span class="educationCenter-lh-25">其他日程</span>
      </div> -->
      <!-- <div class="flex flex-wrap-wrap margin-left-xs">
        <div class="educationCenter-div educationCenter-olorCode-f"></div>
        <span class="educationCenter-lh-25">含试听学员</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import { formatTime } from '../../../utils/util.js';
export default {
  props: ['types'],
  data() {
    return {
      num: 1,
      weekType: '时刻视图表',
      weekValue: 1,
      tabPosition: 'week',
      startDate: new Date(),
      endDate: new Date(),
      today: new Date(),
      currentDay: new Date(),
      isshow: false,
      value:''
    };
  },
  created() {
    this.init();
  },
  computed: {
    currentDateHtml() {
      let value = ''; 
      var _this  = this;
      if (this.tabPosition === 'day') {
        _this.value = formatTime(this.currentDay, 'yyyy-MM-dd')+'~'+formatTime(this.currentDay, 'yyyy-MM-dd');
        return formatTime(this.currentDay, 'yyyy年MM月dd日');
      } else if (this.tabPosition === 'week') {
        if (this.startDate.getMonth() === this.endDate.getMonth()) {
          _this.value  = formatTime(this.startDate, 'yyyy-MM-dd') + '~' + formatTime(this.endDate, 'yyyy-MM-dd');;
          return formatTime(this.startDate, 'yyyy年MM月dd日') + ' - ' + formatTime(this.endDate, 'dd日');
        } else {
          _this.value  = formatTime(this.startDate, 'yyyy-MM-dd') + '~' + formatTime(this.endDate, 'yyyy-MM-dd');;
          return formatTime(this.startDate, 'yyyy年MM月dd日') + ' - ' + formatTime(this.endDate, 'MM月dd日');
        }
      } else if (this.tabPosition === 'month') {
        _this.value  = formatTime(this.startDate, 'yyyy-MM-dd') + '~' + formatTime(this.endDate, 'yyyy-MM-dd');;
        return formatTime(this.startDate, 'yyyy年MM月dd日') + '~' + formatTime(this.endDate, 'MM月dd日');
      }
    }
  },
  watch:{
    value:function(){
      this.dateChangeEmit()
    }
  },
  methods: {
    init() {
      let today = new Date();
      this.today = today;
      this.currentDay = today;
      this.initStartDateAndEndDate(today);
    },
    initStartDateAndEndDate(today) {
      let day = today.getDay();
      if (this.tabPosition === 'day') {
      } else if (this.tabPosition === 'month') {
        this.startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        this.endDate = this.setDate(new Date(today.getFullYear(), today.getMonth() + 1, 1), -1);
      } else if (this.tabPosition === 'week') {
        this.startDate = this.setDate(today, -(day - 1));
        this.endDate = this.setDate(today, 7 - day);
      }
      this.dateChangeEmit();
    },
    setDate(date, dayCount) {
      return new Date(date.getTime() + 1000 * 60 * 60 * 24 * dayCount);
    },
    getTodayDay() {
      this.init();
    },
    changeDate(flag) {
      if (this.tabPosition === 'day') {
        this.currentDay = this.setDate(this.currentDay, flag);
      } else if (this.tabPosition === 'week') {
        this.startDate = this.setDate(this.startDate, flag * 7);
        this.endDate = this.setDate(this.endDate, flag * 7);
        this.currentDay = this.startDate;
      } else if (this.tabPosition === 'month') {
        let today = this.startDate;
        this.startDate = new Date(today.getFullYear(), today.getMonth() + flag, 1);
        this.endDate = this.setDate(new Date(this.startDate.getFullYear(), this.startDate.getMonth() + 1, 1), -1);
        this.currentDay = this.startDate;
      }
      this.dateChangeEmit();
    },
    dateChangeEmit() {
      this.$emit('dateChange', {
        date: [this.startDate, this.endDate, this.currentDay, this.weekValue],
        type: this.tabPosition,
        value:this.value
      });
    },
    typesChange(val) {
      this.tabPosition = val;
      this.initStartDateAndEndDate(this.currentDay);
      this.dateChangeEmit();
    },
    switchView() {
      this.num++;
      if (this.num % 2 == 0) {
        this.weekType = '固定式图';
        this.weekValue = 2;
      } else {
        this.weekType = '时刻视图表';
        this.weekValue = 1;
      }
      this.dateChangeEmit();
    }
  }
};
</script>

<style></style>
