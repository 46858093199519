<template>
  <div class="padding">
    <!-- 日程列表 -->
    <div class="work-title bg-white ">
      <div class="work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap">
        <div>
          <el-button type="primary" size="small" @click="goNav('/educationCenter/newSchedule')">新建排课</el-button>
          <el-button size="small" type="primary" >导入排课</el-button>

          <el-button size="small" type="info" @click="window.open('http://ds.antissoft.com/DownFile/DownCuuriculumTemp')">导入模板下载(.xls)</el-button>
          <!-- <el-button @click="editHandle(2)">一对一排课导入</el-button> -->
        </div>
        <div class="work-title-search flex aligin-items-center">
          <div class="margin-right">
            <el-input placeholder="请输入班级/一对一" v-model="schedule.classInput" class="input-with-select" size="small" style="width: 300px;">
              <el-button slot="append" icon="el-icon-search" />
            </el-input>
          </div>
          <!-- 筛选 -->
          <el-popover placement="bottom" width="300" trigger="click">
            <div class="as-autocomplete-box">
              <div class="as-autocomplete-list flex flex-direction aligin-items-center">
                <el-date-picker
                  v-model="classTime"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="上课开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  style="width: 250px;"
                ></el-date-picker>
                <div class="padding-top-sm ">
                  <el-select v-model="queryList.school_id" multiple placeholder="校区">
                    <el-option v-for="item in schoolList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </div>
                <div class="padding-top-sm ">
                  <el-select v-model="queryList.curriculum_week" multiple placeholder="星期">
                    <el-option v-for="item in weekList" :key="item.value" :label="item.label" :value="item.label"></el-option>
                  </el-select>
                </div>

                <div class="padding-top-sm ">
                  <el-select v-model="queryList.curriculumntype" multiple filterable allow-create default-first-option placeholder="课程类别">
                    <el-option v-for="item in courseClassList" :key="item.id" :label="item.title" :value="item.id"></el-option>
                  </el-select>
                </div>
                <div class="padding-top-sm ">
                  <el-select v-model="queryList.teacher_id" filterable placeholder="教师">
                    <el-option :label="item.full_name" :value="item.id" v-for="item of teacherList" :key="item.id" />
                  </el-select>
                </div>
                <div class="padding-sm border-bottom-grey">
                  <el-select v-model="queryList.curriculumntype" multiple filterable allow-create default-first-option placeholder="课程大类">
                    <el-option v-for="item in courseClassList" :key="item.id" :label="item.title" :value="item.id"></el-option>
                  </el-select>
                </div>
                <!-- <div class="padding-top-sm ">
                  <el-select v-model="queryList.curriculumntype" multiple filterable allow-create default-first-option placeholder="课程">
                    <el-option v-for="item in campusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </div> -->
                <div class="padding-top-sm ">
                  <el-select v-model="queryList.subject_id" multiple filterable allow-create default-first-option placeholder="科目">
                    <el-option v-for="item in SubjectTypeList" :key="item.id" :label="item.title" :value="item.id"></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <el-button slot="reference" size="small">筛选</el-button>
          </el-popover>
          <!-- <ScheduleFilter @selectChange="selectChange"></ScheduleFilter> -->
        </div>
      </div>
      <div class="margin-top-xl">
        <div class="flex flex-wrap-wrap">
          <el-button size="small">批量编辑</el-button>
          <el-button size="small" @click="selDel">批量删除</el-button>
          <!-- <el-button> 导出日程</el-button> -->
        </div>
      </div>
      <div class="flex justify-content-between myClient-top-10 bg-z padding-tb-sm"><div>当前结果：共计{{total}}条排课</div></div>
    </div>
    <!-- 列表 -->
    <div style="margin-top: 10px">
      <as-list :ajaxMethods="{ list: 'queryTimeList' }" :listQueryProp="queryList" @SelectionChange="selectAllChange" @requestChange="requestSuccess">
        <el-table-column align="center" type="selection" width="55"></el-table-column>
        <el-table-column align="center" sortable prop="class_begin_date" label="上课日期" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.class_begin_date | dateTimeFilter('yyyy-MM-dd') }}({{ scope.row.curriculum_week }})</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="时间段" width="130">
          <template slot-scope="scope">
            <span>{{ scope.row.class_begin_time_start }}-{{ scope.row.class_begin_time_end }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="arrange_classes_name" label="班级/一对一" width="250"></el-table-column>
        <el-table-column align="center" prop="arrange_teacher_name" label="教师" width="90"></el-table-column>
        <el-table-column align="center" prop="class_room_name" label="教室" width="120"></el-table-column>
        <el-table-column v-if="checkedCities.indexOf('科目') > -1" align="center" prop="arrange_subject_name" label="科目" width="80" />
        <el-table-column v-if="checkedCities.indexOf('课程') > -1" align="center" prop="curriculum_name" label="课程" width="120" />
        <el-table-column v-if="checkedCities.indexOf('助教') > -1" align="center" prop="arrange_assistant_name" label="助教" width="75" />
        <el-table-column align="center" prop="status_name" label="记上课状态" />
        <el-table-column v-if="checkedCities.indexOf('实到/应到') > -1" align="center" prop="status" label="实到/应到" width="85" />
        <el-table-column align="center" fixed="right" width="120">
          <template slot="header" slot-scope="scope">
            <span>
              操作
              <i class="iconfont icon-diandian margin-left-sm cursor" style="color:#0081FF;" @click="editHandle(1)"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <el-dropdown trigger="click">
              <label>
                操作
                <i class="el-icon-arrow-down el-icon-caret-bottom"></i>
              </label>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>编辑记上课</el-dropdown-item>
                <el-dropdown-item>取消记上课</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </as-list>
    </div>
    <!-- 自定义显示 -->
    <el-dialog title="自定义选项" :visible.sync="editVisible" :append-to-body="true" :close-on-click-modal="false" :close-on-press-escape="false" width="30%">
      <div>
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"  @change="selAll">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="checkedCities">
          <el-checkbox v-for="city in cities" :label="city" :key="city">{{ city }}</el-checkbox>
        </el-checkbox-group>
        <div class="flex justify-content-flexEnd margin-top-lg">
          <el-button type="primary" @click="editVisible = false">确定</el-button>
          <el-button class="myClient-left-30" @click="editVisible = false">取消</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 删除日程 -->
    <el-dialog title="删除日程" :visible.sync="delVisible" width="30%">
      <span>是否确认删除日程？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delVisible = false">取 消</el-button>
        <el-button type="primary" @click="selDel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { weekList } from '@/config/index';
export default {
  data() {
    return {
      total:0,
      // 筛选
      weekList,
      classTime: [],
      queryList: {
        KeyWord: '',
        class_begin_date: '',
        school_id: [],
        curriculumntype: [],
        curriculumn_id: [],
        subject_id: [],
        teacher_id: [],
        roomid: [],
        status: [], // 排课状态（是否记上课)
        curriculum_week: [],
        time_slot: '',
        today: false,
        PageIndex: 1,
        PageSize: 10
      },
      enrollmentList: [
        {
          StudentName: '周杰',
          gender: '女',
          relationship: '母亲',
          phone: '13200001231',
          schoolManager: '何姝',
          attention: '',
          bind: '',
          humanFace: '',
          arrears: '10000',
          status: '在读',
          school: '驿都城校区'
        }
      ],
      schedule: {
        //日程表xgzg_zhouchao
        classInput: '', //输入班级/一对一
        typechange: ''
      },
      checkedCities: [],
      isIndeterminate: true,
      checkAll: false,
      cities: ['课程', '助教', '科目', '实到/应到'],
      editVisible: false,
      selIDList: [],
      delId: 0,
      listQuery1: {
        HasRole: false,
        module: '',
        PageIndex: 1,
        PageSize: 10
      },
      schoolList: [],
      courseClassList: [],
      SubjectTypeList: [],
      teacherList: [],
      classroomList: [],
      delVisible: false
    };
  },
  created() {
    this.getCourseClass();
    this.getSchoolList();
    this.getSubjectTypeList();
    this.getTeacherList();
    this.getClassroomList();
  },
  watch: {
    classTime(val) {
      console.log(val);
      this.queryList.time_slot = val.join('~');
    }
  },
  methods: {
    requestSuccess(data){
      this.total = data.total || 0;
    },
    // 获取校区
    getSchoolList() {
      let obj = {
        type: 1,
        PageIndex: 1,
        PageSize: 10
      };
      this.API.OrganizationList(obj).then(res => {
        if (res.success) {
          this.schoolList = res.data.rows;
        }
      });
    },
    // 获取课程类别
    getCourseClass() {
      this.listQuery1.module = 'course_class';
      this.$Dictionary.GetDictionarys(this.listQuery1).then(res => {
        this.courseClassList = res.data.rows;
        this.$forceUpdate();
      });
    },
    // 获取科目
    getSubjectTypeList() {
      this.listQuery1.module = 'SubjectType';
      this.$Dictionary.GetDictionarys(this.listQuery1).then(res => {
        this.SubjectTypeList = res.data.rows;
        this.$forceUpdate();
      });
    },
    // 获取教师
    getTeacherList(keyword) {
      let obj = {
        AccountType: [4],
        KeyWord: keyword,
        PageIndex: 1,
        PageSize: 10
      };
      this.API.accountQuery(obj).then(res => {
        this.teacherList = res.data.rows;
      });
    },
    // 获取教室
    getClassroomList() {
      let query = {
        shoolid: 0,
        KeyWord: '',
        PageIndex: 1,
        PageSize: 10
      };
      this.API.getClassroomList(query).then(res => {
        this.classroomList = res.data.rows;
      });
    },
    // 批量选择
    selectAllChange(e) {
      this.selIDList = [];
      e.map(t => {
        this.selIDList.push(t.id);
      });
    },
    // 批量删除
    selDel() {
      this.delId = 0;
      if (this.selIDList.length > 0) {
        this.delVisible = true;
        this.delId = this.selIDList.join(',');
      } else {
        this.$message.error('请选择班级');
      }
    },
    // 确认删除
    sureDelclass() {
      const delData = {
        ids: this.delId,
        status: -99
      };
      this.API.updatearrangeCurriculumStatus(delData).then(res => {
        if (res.code == 200) {
          this.delVisible = false;
          this.$refs.reload.reload();
          this.$message.success('删除成功');
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 自定义显示全部
    selAll(val) {
    	this.checkedCities = val ? this.cities : [];
    	this.isIndeterminate = false;
    },
    editHandle() {
      this.editVisible = true;
    },
    goNav(url) {
      this.$router.push(url);
    },
    selectChange(item) {}
  }
};
</script>

<style scoped></style>
